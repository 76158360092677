import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from '@/app/common/style/functions';
import { body4, h2, h3 } from '@/app/common/style/typography';
import { COLOR, FONTS } from '@/app/common/style/variables';
import {
  desktopAndBelow,
  tabletAndBelow,
  twoColumnFlex,
} from '@/app/common/style/mixins';

const PrivacyStyle = css`
  .privacy-page {
    padding-bottom: 104px;

    .post-header {
      margin-top: 80px;

      .date-container {
        margin-bottom: 20px;

        .date {
          ${body4}
          color: ${COLOR.woodSmoke3};
        }
      }
      .main-details {
        ${twoColumnFlex()}
        margin-bottom: 72px;

        .title-container {
          display: flex;
          align-items: center;

          h1 {
            /* @extend .h2; */
            ${h2}
            margin-bottom: 0;
          }
        }
      }
    }

    .post-container {
      max-width: 730px;
      margin: 0 auto;
    }

    .intro {
      margin-bottom: 72px;
      h1 {
        ${h2}
      }
      p {
        ${h3}
        color: ${COLOR.woodSmoke2};
      }
    }

    .content {
      margin-bottom: 64px;
      h2 {
        ${h3}
      }
      h4 {
        margin-bottom: 24px;
      }

      p {
        color: ${COLOR.woodSmoke3};
      }

      ul {
        list-style-type: disc;
        margin-bottom: 28px;

        li::marker {
          font-size: 0.8em;
        }
      }

      ol {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    ${desktopAndBelow(css`
      .post-header {
        .main-details {
          flex-direction: column-reverse;

          > div {
            &:not(:last-child) {
              padding: 40px 0 0 0;
            }

            &:not(:first-of-type) {
              padding: 0 0 40px;
            }
          }
        }
      }

      .post-container {
        max-width: 700px;
      }
    `)}

    ${tabletAndBelow(css`
      padding-bottom: 88px;

      .post-header {
        margin-top: 24px;

        .date-container {
          margin-bottom: 16px;

          .date {
            font-size: ${rem(14)};
            line-height: 22px;
            font-weight: ${FONTS.light};
          }
        }

        .main-details {
          margin-bottom: 32px;

          > div {
            &:not(:last-child) {
              padding-top: 16px;
            }

            &:not(:first-of-type) {
              padding-bottom: 16px;
            }
          }
        }
      }

      .post-container {
        .intro {
          margin-bottom: 56px;
        }
      }

      .content {
        margin-bottom: 56px;

        h4 {
          margin-bottom: 8px;
        }
      }

      .author-container {
        flex-direction: column;
        margin-bottom: 56px;

        .description {
          margin-bottom: 32px;
        }

        .author-details {
          .about-author {
            padding-left: 24px;

            p,
            a {
              &:not(.name) {
                font-size: ${rem(14)};
                line-height: 22px;
              }
            }
          }
        }
      }

      .post-footer {
        .share-text {
          margin-bottom: 8px;
        }

        .share {
          flex-wrap: wrap;

          .link {
            margin-left: 0;
            margin-right: 38px;
          }
        }
      }
    `)}
  }
`;

const PrivacyStyled = styled.main`
  ${PrivacyStyle}
`;
export default PrivacyStyled;
