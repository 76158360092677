import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import PrivacyStyled from '@/components/styled/PrivacyStyled';

interface IPrivacyProps extends PageProps {
  data: {
    contentfulBlock: {
      id: string;
      content: {
        raw: string;
      };
    };
  };
}

const Privacy: React.FC<IPrivacyProps> = ({ data }) => (
  <PrivacyStyled>
    <div className="content">
      <div className="container privacy-page">
        <div className="page-title link-title">
          <Link className="link back" to="/">
            Home page
          </Link>
        </div>
        <div className="post-container">
          <div className="intro">
            <h1>IONA&apos;s Privacy Policy</h1>
          </div>
          <div className="content">
            <ContentfulRawToComponent raw={data.contentfulBlock.content.raw} />
          </div>
        </div>
      </div>
    </div>
  </PrivacyStyled>
);

export const query = graphql`
  query policy {
    contentfulBlock(pages: { eq: "privacy" }) {
      id
      content {
        raw
      }
    }
  }
`;
export default Privacy;
